import React from "react";
import './styles.scss';

const Footnotes = ({ footnotes }) => {
    return (
    <div className="zt-footnotes">
        <ol>
            {footnotes.map((text, index) => (
                <li id={`footnote${index}`}>{text} <a href={`#refnote${index}`}>↩</a></li>
            ))}
        </ol>
    </div>
    );
};

export default Footnotes;