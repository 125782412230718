import React from "react";
import MenuButton from 'components/buttons/Menu';
import { Menu as MenuIcon } from "components/Icons/Glyphs";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import ForPromoter from "pages/ForPromoter";
import Menu from "components/Menu";
import Help from "pages/Help";
import './styles.scss';

const ACTIONS = {
    FORPROMOTER: 'forpromoter',
    HELP: 'help'
}

export default function HumbMenu() {

    const { t } = useTranslation('humbmenu');

    // const navigate = useNavigate();

    const actions = action => {
        switch(action.id) {
            case ACTIONS.FORPROMOTER:
                // navigate(ForPromoter.path);
                window.location.href = ForPromoter.path;
                break;
            case ACTIONS.HELP:
                // navigate('/help');
                window.location.href = Help.path;
                break;
            default:
                break;
        }
    }

    return (
        <MenuButton icon={MenuIcon} label={''} className={'zt-hambmenu'}>
            <Menu items={[
                {id: ACTIONS.FORPROMOTER, label: t('forpromoter', 'For promoters')},
                {id: ACTIONS.HELP, label: t('help', 'Help')}
                ]} onSelect={action => actions(action)} />
        </MenuButton>
    );
}