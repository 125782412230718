import Image1 from './images/1-image.svg';
import Image2 from './images/qr.svg';
import Image21 from './images/21-image.svg';
import ImageMerch from './images/merch_fixed.svg';
import Image3 from './images/3-image.svg';
import ImageID from './images/idf.svg';

export default [
    {
        "title": "Vill du erbjuda dina kunder säkra och legitima biljetter?",
        "items": [
            `ZipperTic är ett patenterat<SUP>1</SUP> biljettsystem som tar bort falska och dyra biljetter på
            andrahandsmarknaden. Vi skannar alla besökares biljetter och ID vid entrén i ett
            moment! Bara förstahandsbiljetter där ni som arrangör sätter priset. Inga botar kan
            köpa biljetter!`
        ]
    },
    {
        "title": "Hur ska du kunna nå ut till fler utan dyra kampanjer?",
        "items": [
            `En del hittar era evenemang på sociala medier, er hemsida eller via ert nyhetsbrev. Det
är förmodligen er mest trogna skara som också registrerar sig och köper biljetter till sig
själva och sina vänner. Men vilka är vännerna som följde med på evenemanget? Om de
tyckte det var bra så kan de säkert gå fler gånger! Hur kan ni nå dem?`,
            `Det vet ZipperTic! Alla besökare över 12 år registreras enligt GDPR (andra system
registrerar bara de som köper biljetter). Förstahandsdata blir allt viktigare i dagens
digitala värld och det är precis det ZipperTic kan erbjuda. Att biljetterna distribueras ut
till alla besökare vid köpet ger er data på alla besökare och en ypperlig möjlighet att öka
er försäljning med hjälp av dessa nya personuppgifter som ni inte kommer åt idag.`,
            {"image": Image2, "style": {"margin-top": "-20px", "margin-bottom": "-40px"}},
            `Inte nog med att ni kan kommunicera med alla besökare, ni kan kommunicera med
alla era besökare på fler sätt: email, social media, mobilnummer, push-notifieringar
och in-appnotiser. Ni kan kommunicera med besökarna under hela kundresan då
alla biljetter distribueras direkt vid köpet. Många möjligheter att skicka olika
erbjudanden och rabattkoder!`,
            {"image": Image21},
            `Vi kan segmentera era kunder på olika sätt så att ni kan anpassa era erbjudanden
efter era kunders köphistoria och behov. Självklart ingår möjligheten att erbjuda
medlemskort.`
        ]
    },
    {
        "title": "Fler erbjudanden under hela kundresan ger merförsäljning!",
        "items": [
            `ZipperTic har en unik shop till varje event där ni kan marknadsföra produkter till alla
besökare. Enkelt att paketera, men också anpassa efter era kunders kundresor.`,
            {"image": ImageMerch, "style": {"margin-top": "-45px", "margin-bottom": "-100px"}},
            `Köp biljett först, komplettera sedan med merchandise, mat och dryck. Vid förköp
kan du ha mycket tid och många chanser att erbjuda din kund olika produkter! Bra
att kunna utnyttja det. Avsluta med en rabattkod till nästa evenemang.`,
            {"image": Image3},
            `Tycker ni att era kassasystem är dyra och kräver mycket administration? ZipperTic är
en lösning som kan ersätta traditionella kassasystem med kassaregister. Godkänd
av Skatteverket. Det har visat sig att en app kan öka försäljningen dramatiskt
jämfört med traditionella kassasystem.`
        ]
    },
    {
        "title": "Det blir enklare för er publik med ZipperTic.",
        "items": [
            `Superenkel registrering/login med eID (t ex BankID). En gång i livet! Inga nya
lösenord att komma ihåg.`,
            {"image": ImageID},
            `Era kunder kan enkelt sälja sin biljett vid förhinder utan att kontakta kundtjänst, gå
till en annan plattform eller ange krångliga koder på en websida etc. Tre klick i
appen och du har lagt ut din biljett till försäljning!`,
            `Vi kan även skanna nationellt ID, körkort, pass som en giltig biljett. Perfekt för de
som inte använder smartphones eller har en trasig skärm som inte kan skannas.`,
            `Vi har säsongskort/festivalkort där kunden, vid förhinder, kan sälja en enskild
match/föreställning som ingår i kortet. Arrangören får tillbaka den ursprungliga
rabatten och får personuppgifter på den nya köparen.`,
            `Alla har varsin biljett och kan gå in i stället för att vänta på den som har biljetterna.`,
            `Ibland är biljetter dyra och det kan vara svårt att ligga ute med pengar för alla i
sällskapet. I ZipperTic kan du boka platser för alla i sällskapet och välja vilka du
betalar för.`,
            `Medlemskort och medlemsregister ingår!`
        ]
    },
    {
        "title": "Vill du erbjuda dina kunder säkra evenemang?",
        "items": [
            `Förhindra avstängda gäster att köpa, ta emot eller inneha giltiga biljetter under en
viss tid.`,
            `Vid en olycka kan du kommunicera med alla dina besökare och snabbt kunna få
kontroll om en besvärlig situation skulle uppstå.`,
            `Garanterad skalning vid stora event (vi använder AWS) så att alla kan köpa biljett vid
stor efterfrågan.`
        ]
    }
];