import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import Divider from 'components/Divider';
import './style.scss';
import Article from './components/Article';
import Banner from 'components/Header/Banner';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import Error from 'pages/Event/components/Error';
import useFetchItems from './useFetchItems';
import { useTranslation } from 'react-i18next';

export const path = '/help';
export const link = path;


export const Help = props => {

    const { state: { loading, items, error }, fetch } = useFetchItems();
    const [opened, setOpened] = useState();

    const [t, i18n] = useTranslation('help');
    const { language } = i18n;

    useEffect(() => {
        fetch();
    }, [language]);


    if (loading) {
        return <MiniLoader />
    }

    if (error) {
        return <div className='zt-help'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="9"><Error retry={() => fetch()} /></Col>
                    </Row>
                </Container>
            </div>
    }

    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
            <div className='zt-help'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs lg="9">
                            <h4 className='zt-help__header bold'>{t('label', 'Help')}</h4>
                            {items?.map((item, key) => <Article {...{key, item, opened: opened == item.id, onOpen: () => setOpened(item.id) }} />)}
                            <Banner />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )
}

Help.path = path;

export default Help;