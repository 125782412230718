import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Flags from './flags';
import './style.scss';

const SwitchItem = ({ selected, children, ...props }) => <li className={`zt-switch__item ${selected ? 'zt-switch__item--active' : ''}`} {...props}>{children}</li>

export default props => {

    const [, i18n] = useTranslation();
    const { language } = i18n;
    const [state, setState] = useState(language);

    const changeLang = useCallback(lang => {
        setState(lang);
        i18n.changeLanguage(lang);
    },[i18n, setState]);
    
    useEffect(() => {
        setState(language);
    }, [language]);

    return (
        <ul className='zt-switch'>
            <SwitchItem selected={state === 'en'} onClick={() => changeLang('en')}><Flags.EN /></SwitchItem>
            <SwitchItem selected={state === 'se'} onClick={() => changeLang('se')}><Flags.SE /></SwitchItem>
        </ul>
    )
}