import React from 'react';
import './style.scss';
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Banner from 'components/Header/Banner';

export const Article = ({ item, n, ...props}) => <div className='zt-info__article'>
    <h4 className='zt-info__title light'>{`${n}. ${item.title}`}</h4>
    {item?.items?.map( (text, i) => <div key={i} className='zt-info__article__text'>{`${n}.${i+1}. ${text}`}</div>)}
</div>

export default ({ t, ...props }) => {
    const paragraphs = t('paragraphs') || [];
    const articles = t('articles') || [];

    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
            <div className='zt-info'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs md={10} lg={8}>
                            <h4 className='zt-info__header bold'>{t('label')}</h4>
                            
                            {paragraphs.map((p, key) => <div {...{key}} className='zt-p1 zt-info__text'>{p}</div>)}

                            {articles.map((a, i) => <Article key={i} n={i+1} item={a} />)}

                            <Banner />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    );
}