import React from "react";
import { CSSTransition } from "react-transition-group";
import { Col, Container, Row } from "react-bootstrap";
import Footnotes from "./components/Footnotes";
import Banner from "components/Header/Banner";
import { Trans, useTranslation } from "react-i18next";
import articles from "./articles";
import './styles.scss';


const Image = ({ style={}, children }) => <div className='zt-forpomoter--image' {...{style}}>{children}</div>;

const SUP = ({ children, index: i }) => <a href={`#footnote${i}`} id={`refnote${i}`}><sup>{children}</sup></a>;

const Line = () => <div style={{textAlign: 'center'}}>---</div>;

export const Article = ({ t, item, n, ...props}) => <div className='zt-info__article'>
    <h4 className='zt-info__title bold'>{`${item.title}`}</h4>
    {item?.items?.map( (text, i) => <div key={i} className='zt-info__article__text'>
        {(typeof text === 'string') && (
            <Trans {...{t, i18nKey:'forpromoter'}} components={{ SUP: <SUP index={i} />, linebr: <Line />, u: <u />}}>
                {`${text}`}
            </Trans>
        )}
        {(typeof text === 'object') && text.image && <Image {...(text.style ? {style: text.style} : {})}><img width="80%" src={text.image} alt={item.title} /></Image>}
    </div>)}
    {item?.image && <Image><img width="80%" src={item?.image} alt={item.title} /></Image>}
</div>

const ForPromoter = () => {
    const { t } = useTranslation("forpromoter");

    const footnotes = [
        `Vårt patent omfattar en säker personlig digital biljett som vid skanningen kontrollerar både en giltig biljett och ID. Patentet omfattar även
försäljning av den digitala biljetten och att biljetterna distribueras till alla i sällskapet vid köpet.`
    ]

    return (
        <CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
            <div className='zt-info'>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs md={10} lg={8}>
                            <h4 className='zt-help__header bold' style={{textAlign: 'center', color: '#C00000', fontSize: '32px', lineHeight: '32px'}}>
                            Säkerhet och CRM i världsklass!
                            </h4>
                            {articles.map((a, i) => <Article opened={true} key={i} n={i+1} item={a} {...{t}} />)}
                            <center><p>Hör av er till oss så berättar vi mer: <a href="#">info@zippertic.se</a></p></center>
                            <Footnotes {...{footnotes}} />
                            {/* <Banner /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    );
}

ForPromoter.path = '/forpromoter';

export default ForPromoter;