import React, { useState, useRef, useEffect } from 'react';
import { DropdownIcon, ClearIcon } from 'components/Icons/Glyphs';
import { CSSTransition } from 'react-transition-group';
import './style.scss';

export default props => {
    const ref = useRef();
    const [active, setActive] = useState(false);
    const selected = !!props.selected;

    const onClick = () => {
        if (selected && props.onUnSelect) {
            props.onUnSelect();
        } else {
            setActive(true);
            if (props.onOpen) {
                props.onOpen();
            }
        }
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setActive(false);
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });    

    return (
       
        <div className={'zt-dropdown'} ref={ref}>
            <button className={`zt-dropdown__button d-flex justify-content-center align-items-center ${active ? 'zt-dropdown--active' : (props.selected ? 'zt-dropdown--selected' : null)}`} 
            
                onClick={onClick}
                >
                { props.icon &&
                    <props.icon className='zt-dropdown__icon'/>
                }
                <span className={`zt-nav ${props.selected ? null : 'light'}`}>{props.selected || props.label}</span>
                {selected ?
                <ClearIcon />
                :
                <DropdownIcon />
                }
            </button>
            { active &&
            <CSSTransition in={true} appear={true} timeout={200} classNames="zt-menu__transition">
                <div className='zt-dropdown__content'>
                    {React.cloneElement(props.children, {onClose: () => setActive(false)})}
                </div>
            </CSSTransition>
            }        
        </div>
    );
};